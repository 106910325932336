<template>
    <div class="airplantPart">
        <div class="airplantPartImg">
            <img :src="airplantData.topSrc" alt="图片加载中" class="airplant-image">
        </div>

        <div class="produceAirplant">
            <div class="AirplantTitle">
                <img style="margin-right: 16px;" src="../../src/assets/rectangle.png" alt="">
                <span class="carTitle">
                    {{ airplantData.title1 }}
                </span>
            </div>
            <div class="AirplantMessage">
                {{ airplantData.message1 }}
            </div>
        </div>
        <!-- 系统特点 -->
        <div class="productParams" v-if="airplantData.sysFeature">
            <div class="productParamsTitle">
                <img style="margin-right: 16px;" src="../../src/assets/paramsIcom.png" alt="">
                <span class="carTitle"> {{ airplantData.title3 }}</span>
            </div>
            <div class="productFeature">
                <div class="productFeatureItem" v-for="(item,index) in airplantData.sysFeature"
                    :style="getMarginStyle(index)"
                   
                    :key="index">
                    <div class="iconBox">
                        <img class="itemIcon" :src="item.FeatureIcon" alt="">
                    </div>
                    <span class="itemWorld"> {{ item.iconWorld }}</span>
                    <span class="itemMessage"> {{ item.produceMessage }}</span>
                </div>
            </div>
        </div>
        <!-- 系统特点结束 -->
        <div class="productParams">
            <div class="productParamsTitle">
                <img style="margin-right: 16px;" src="../../src/assets/paramsIcom.png" alt="">
                <span class="carTitle"> {{ airplantData.title2 }}</span>
            </div>
            <div class="productParamsMessage">
                <div>
                    {{ airplantData.message2 }}
                </div>
                <div>
                    {{ airplantData.message3 }}
                </div>
                <div>
                    {{ airplantData.message4 }}
                </div>
                <div>
                    {{ airplantData.message5 }}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        airplantData: {
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
        }
    },
   methods:{
       getMarginStyle(index) {
           console.log(this.titleSelect, 'this.titleSelect');

           if ([1, 4].includes(index)) {
               return { marginLeft: '1%', marginRight: '1%' };
           }
           return {};
       },
    }
}

</script>
<style lang="scss" scoped>
.airplantPart {
    width: 100%;
    margin: 62px 0 80px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    .airplantPartImg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 471px;
        overflow: hidden;
        background: #f7f7f7;
        border-radius: 12px;

        .airplant-image {
            max-width: 100%;
            /* 图片最大宽度为盒子宽度 */
            height: auto;
        }
    }

    .produceAirplant {
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        .AirplantTitle {
            display: flex;
            align-items: center;
            margin: 86px 0 46px 0;

            .carTitle {
                font-size: 38px;
                font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                font-weight: normal;
                color: #333333;
                line-height: 44px;
                letter-spacing: -0.76px;
            }
        }

        .AirplantMessage {
            text-align: start;
            font-size: 28px;
            font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
            font-weight: normal;
            color: #333333;
            line-height: 48px;
            letter-spacing: 0.56px;
        }
    }

    .productParams {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        .productParamsTitle {
            display: flex;
            align-items: center;
            margin: 40px 0 46px 0;
            width: 100%;
            .carTitle {
                font-size: 38px;
                font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                font-weight: normal;
                color: #333333;
                line-height: 44px;
                letter-spacing: -0.76px;
            }
        }

        .productParamsMessage {
            margin-left: 3%;
            width: 88.58%;
            height: 276px;
            display: flex;
            justify-content: space-between;
            align-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            font-size: 30px;
            font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
            font-weight: normal;
            color: #212121;
            line-height: 73px;
        }
        .productFeature{
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            .productFeatureItem{
                width: 32%;
                background-color: white;
                display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 1%;
                .iconBox{
                    // width: 36.45%;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    background: #0467bf;
                    overflow: hidden;
                    margin-top: 31px;
                  .itemIcon{
                   max-width: 100%;
        /* 图片最大宽度为盒子宽度 */
                    height: auto;
                    }
                }
                .itemWorld{
                font-size: 32px;
                    font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                    font-weight: normal;
                    color: #0467bf;
                    line-height: 77px;
                    letter-spacing: 0.64px;
                }
                .itemMessage{
                    font-size: 20px;
                        font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                        font-weight: normal;
                        color: #000000;
                        line-height: 38px;
                        margin: 0 5% 45px 4%;
                }
            }
                        .productFeatureItem:hover {
                        box-shadow: 1px 7px 18px 0px rgba(117, 117, 117, 0.59);
                            background-color: #F7F7F7;
                        }
        }
    }
}
</style>
