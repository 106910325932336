<template>
    <div v-if="isPCport" class="eleDistrubTotal">
        <header class="header">
            <div class="left">
                <img class="leftImg1" @click="$router.push('/')" src="../../src/assets/LOGO.png" alt="">
                <img class="leftImg2" src="../../src/assets/headTitle.png" alt="">
            </div>
            <div class="right">
                <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
                    <el-tab-pane label="首页" name="1"></el-tab-pane>
                    <el-tab-pane label="产品中心" name="2"></el-tab-pane>
                    <el-tab-pane label="行业应用" name="3"></el-tab-pane>
                    <el-tab-pane label="关于容远" name="4"></el-tab-pane>
                    <el-tab-pane label="联系我们" name="5"></el-tab-pane>
                </el-tabs>


            </div>
        </header>

        <main class="main">
            <div class="banner">
                <img class="bannertuceng" src="../assets/aboutRY/aboutRY-tuceng.png" alt="">
            </div>
            <div class="bodys">
                <div class="part1">
                    <div class="part1Title">成都国科容远科技有限公司</div>
                    <div class="part1Message">下设 <span class="hover-text">北京中电联达信息技术有限公司</span>和
                        <span class="hover-text">成都融达昌腾信息技术有限公司</span>两家子公司
                    </div>
                    <div class="part1Img"></div>
                </div>
                <div class="part2">
                    <div class="introduceworld">
                        <p>
                            公司专注于国防装备智能化领域，以提升装备的智能感知、分析及控制能力为目标，形成了基于国产化平台的智能控制技术、多元感知处理与协同控制技术、多态势融合算法及人工智能识别技术等核心技术。公司的综合管控系统、无人机探测与反制系统、智能识别产品已获得多个产品型号并实现批量供应。
                        </p>
                        <p>
                            公司拥有完备的军工资质，已取得国军标质量体系认证、武器装备科研生产单位保密资格，及武器装备承制资格。通过多年技术积累，公司已取得了国家高新技术企业证书、双软证书、并拥有100+软著、20+专利，产品的各项技术指标处于行业领先水平。
                        </p>
                        <p>
                            公司与航天科工、中国电科、中国电子、中国船舶、中航工业、中兵工业等军工集团建立了深度合作，进入了多家研究所的合格供方名录。
                        </p>
                    </div>

                </div>
                <div class="part3">
                    <div class="productFeatureItem" v-for="(item, index) in produceList" :style="getMarginStyle(index)"
                        :key="index" :class="{ 'active': activeImgIndex === index }" @mouseenter="setActiveIndex(index)"
                        @mouseleave="setActiveIndex(null)">
                        <div class="iconBox">
                            <img class="itemIcon" :src="item.FeatureIcon" alt="">
                        </div>
                        <span class="itemWorld"> {{ item.iconWorld }}</span>
                    </div>
                </div>

            </div>
        </main>
        <div class="part4" ref="part4">
            <div class="developMentWorld">发展历程</div>
            <div class="HistoryImg">
                <div class="centerImg">
                    <div class="imgItem" v-for="(item, index) in imgItems" :key="index"
                        :style="{ animationDelay: `${index * 0.5}s` }" :class="{ show: item.visible }">
                        <img :src="item.imgSrc" alt="">
                        <ul>
                            <li v-for="(text, textIndex) in item.texts" :key="textIndex">{{ text }}</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
        <footer>

            <div class="footerPart">
                <div class="footleft">
                    <div class="leftertop">
                        <ul>
                            <li s>产品中心</li>
                            <li>无人机探测与反制产品</li>
                            <li>智能管控产品</li>
                            <li>智能识别及处理产品</li>
                            <li>加固信息产品</li>
                        </ul>
                        <ul>
                            <li>行业应用</li>
                            <li>单兵训练</li>
                            <li>海上防导</li>
                            <li>智慧哨塔</li>
                            <li>边境巡逻</li>
                        </ul>
                        <ul>
                            <li>联系我们</li>
                            <li>联系我们</li>
                            <li>商务合作</li>
                        </ul>
                        <ul>
                            <li>关于容远</li>
                            <li>公司简介</li>
                            <li>荣誉资质</li>
                            <li>发展历程</li>
                        </ul>
                    </div>
                    <div class="footleftbottom">
                        © 成都国科容远科技有限公司版权所有 <a style="color: white; text-decoration: none;"
                            href="https://beian.miit.gov.cn/">蜀ICP备2024107035号-1</a>
                    </div>
                    <!-- <span>© 成都国科容远科技有限公司版权所有 蜀ICP备111111114号-1 技术支持 公安备案号：川公网安备12345678998</span> -->
                </div>
                <div class="footright">
                    <div class="footNumber">
                        <div class="footNumberspan1">
                            <span>电话</span> <span class="citymargin">成都</span> <span>028-86248068</span>
                        </div>
                        <div class="footNumberspan2">
                            <span class="citymargin">北京</span> <span>010-84566771</span>
                        </div>
                    </div>
                    <div class="footCode">
                        <!-- <img class="QRcode1" src="../../src/assets/qrCode.png" alt=""> -->
                        <!-- <img class="QRcode2" src="../../src/assets/qrCode.png" alt=""> -->
                        <!-- <router-link to="/twoView">Go to Two View</router-link> -->
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <div v-else class="eleDistrubTotalPhone">
        <header class="header">
            <div class="left">
                <img class="leftImg1" @click="$router.push('/')" src="../../src/assets/phoneImage/LOGO.png" alt="">
                <img class="leftImg2" src="../../src/assets/phoneImage/headTitle.png" alt="">
            </div>
            <div class="right">
                <img v-show="!sidebarOpen" src="../../src/assets/phoneImage/icon_more.png" alt=""
                    @click="sidebarOpen = !sidebarOpen">
                <img v-show="sidebarOpen" src="../../src/assets/phoneImage/guanbi.png" alt="" @click="closeSider()">

            </div>
        </header>

        <main class="main">
            <!-- <div class="carousel">
        <el-carousel indicator-position="outside" class="carouselImg">
          <el-carousel-item v-for="(item, index) in phoneImages" :key="index" class="carouselitem">
            <img class="img" :src="item" alt="轮播图片">
            <div class="sidebarpart" v-if="sidebarOpen">
          <div class="siderinner" v-for="(item, index) in sidebarWorld" :key="index">
              <div class="siderItem" :class="{ active: selectedSidebarIndex === index }" @click="changeSidebar(index)">{{ item }}</div>
          </div>
        </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->

            <div class="trytry11" ref="carouseltry" @mouseenter="stopCarousel" :style="carouselStyle"
                @mouseleave="startCarousel">
                <div class="background11" v-for="(item, index) in newimgphone" :key="index" :style="{
                    backgroundImage: `url(${item})`,
                    transform: `translateX(${(index - currentIndex22) * 100}%)`, transition: 'transform 0.5s ease'
                }">
                    <div class="iconleft" @click="turnleft()">

                    </div>
                    <div class="iconright" @click="turnright()">

                    </div>
                    <div class="turnbuttom">
                        <span v-for="(item, index) in images" :key="index" class="litlecircle"
                            :class="{ 'isActiveHover': index === currentIndex22 }"
                            @click="currentIndex22 = index">1</span>
                    </div>

                </div>
            </div>


            <div class="bodys">

                <div class="body2">
                    <img class="body2Img" src="../../src/assets/phoneImage/title_producterCenter.png" alt="">
                    <div class="body2product">
                        <div class="body2_1" v-for="(item, index ) in phoneProductCenter" :key="index"
                            @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)">
                            <div class="bodyImg">
                                <img :src=item.src alt="">
                            </div>
                            <div class="bodyHover" v-show="currentIndex == index">
                                <div class="circle">
                                    <img src="../../src/assets/home_icon_productcenter_.png" alt="">
                                </div>

                                <div class="hoverWorld">
                                    <div class="hoverWorldTitle">{{ item.title }}</div>
                                    <div class="index34" v-if="index == 3 || index == 4"> <span class="span1">{{
                                            item.smallTitle1 }}</span>
                                        <span class="span1">{{ item.smallTitle2 }}</span>
                                    </div>
                                    <div class="index0125" v-else><span class="span1">{{ item.smallTitle1 }}</span>
                                        <span class="span1">{{
                                            item.smallTitle2 }}</span> <span class="span1">{{ item.smallTitle3 }}</span>
                                    </div>

                                    <div @click="toNextPage" class="detail-link">查看详情 <img class="detail-link-img"
                                            src="../../src/assets/home_icon.png" alt=""></div>
                                </div>
                            </div>
                            <div v-show="currentIndex != index" class="productWorld">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
                <div class="body3">
                    <img class="body3Img" src="../../src/assets/phoneImage/title_industry_app.png" alt="">
                    <div class="body3Part">
                        <div class="image-container">
                            <!-- <img class="body3Img1" src="../../src/assets/eleConfront.png"> -->
                            <div class="eleConfront">
                                低空近程轻高机电子对抗
                            </div>
                        </div>
                        <div class="body3Img2">
                            <div class="body3Img2-1">
                                <div class="eleConfront1">
                                    组网
                                </div>
                            </div>
                            <div class="body3Img2-2">
                                <div class="eleConfront2">
                                    机场低空防御
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="body4">
                    <img class="body4Img" src="../../src/assets/phoneImage/title_aboutRY.png" alt="">
                    <div class="aboutRY">
                        <div class="introduceworld">
                            <p>
                                公司专注于国防装备智能化领域，以提升装备的智能感知、分析及控制能力为目标，形成了基于国产化平台的智能控制技术、多元感知处理与协同控制技术、多态势融合算法及人工智能识别技术等核心技术。公司的综合管控系统、无人机探测与反制系统、智能识别产品已获得多个产品型号并实现批量供应。
                            </p>
                            <p>
                                公司拥有完备的军工资质，已取得国军标质量体系认证、武器装备科研生产单位保密资格，及武器装备承制资格。通过多年技术积累，公司已取得了国家高新技术企业证书、双软证书、并拥有100+软著、20+专利，产品的各项技术指标处于行业领先水平。
                            </p>
                            <p>
                                公司与航天科工、中国电科、中国电子、中国船舶、中航工业、中兵工业等军工集团建立了深度合作，进入了多家研究所的合格供方名录。
                            </p>

                        </div>
                        <div class="iconWorld">
                            <div v-for="(item, index) in aboutRongYuan" :key="index">
                                <img :src=item.src alt=""> <span class="aboutIconworld">{{ item.title }}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="body5">
                    <img class="body5Img" src="../../src/assets/phoneImage/title_callwe.png" alt="">
                    <el-form class="elform" ref="form" :model="form" :rules="rules">
                        <el-form-item class="labelItem" label="姓名：" prop="name">
                            <el-input style="width: 75%;" v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item class="labelItem" label="电话：" prop="telephone">
                            <el-input style="width: 75%;" v-model="form.telephone"></el-input>
                        </el-form-item>
                        <el-form-item class="labelItem" label="单位：" prop="unit">
                            <el-input style="width: 75%;" v-model="form.unit"></el-input>
                        </el-form-item>
                        <el-form-item class="labelItem" label="意向：" prop="resource">
                            <el-radio-group style="width: 75%;" v-model="form.resource">
                                <el-radio label="购买"></el-radio>
                                <el-radio label="合作"></el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item>
                            <!-- <el-button style="width: 634px; height: 99px;" type="primary" @click="onSubmit">立即提交</el-button> -->
                            <img class="body5admit" src="../../src/assets/phoneImage/home_button_submit_press.png"
                                alt="">
                        </el-form-item>
                    </el-form>
                </div>

            </div>

        </main>
        <footer class="footer">

            <div class="top">
                <div>
                    <div class="linkWe">联系我们</div>
                    <div class="sink"></div>
                </div>
                <div class="topLeft">
                    <div>成都</div>
                    <div>028-86248068</div>
                    <div>北京</div>
                    <div>010-84566771</div>
                </div>
                <div class="topRight">
                    <img class="QRcode1" src="../../src/assets/qrCode.png" alt="">
                    <img class="QRcode1" src="../../src/assets/qrCode.png" alt="">
                </div>
            </div>
            <div class="divider"></div>
            <div class="bottom">
                © 成都国科容远科技有限公司版权所有 <a style="color: white; text-decoration: none;"
                    href="https://beian.miit.gov.cn/">蜀ICP备2024107035号-1</a>
            </div>



        </footer>
    </div>

</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        // msg: String
    },
    data() {
        return {
            hasAnimated: false, // 追踪动画是否已经执行
            imgItems: [
                {
                    imgSrc: require('../assets/aboutRY/aboutrongyuan_History2.png') ,
                    texts: ['发布多元感知系统', '发布天极智能分析系统'],
                    visible: false,
                },
                {
                    imgSrc: require('../assets/aboutRY/aboutrongyuan_History1.png'),
                    texts: ['完成纯国产化集控设备研制'],
                    visible: false,
                },
                {
                    imgSrc: require('../assets/aboutRY/aboutrongyuan_History3.png'),
                    texts: ['装备产品标准化定型'],
                    visible: false,
                },
                {
                    imgSrc: require('../assets/aboutRY/aboutrongyuan_History4.png'),
                    texts: ['装备产品进行实战验证', '与深度强化学习AI结合，探索智能决策与深度强化学习AI结合，探索智能决策'],
                    visible: false,
                },
            ],
            activeImgIndex:'',
            produceList: [{
                FeatureIcon: require('../../src/assets/aboutRY/aboutrongyuan_iconCX.png'),
                iconWorld: '诚信·尊重',
                produceMessage: '集探测反制于一体，通过电磁信号侦测目标，使用电磁进行压制与干扰。'
            },
                {
                    FeatureIcon: require('../../src/assets/aboutRY/aboutrongyuan_iconZR.png'),
                    iconWorld: '责任·担当',
                    produceMessage: '采用快速拆卸、组装的接口设计，两个拉杆箱即可收纳所有设备，便于转移。'
                }, {
                    FeatureIcon: require('../../src/assets/aboutRY/aboutrongyuan_iconCX_1.png'),
                    iconWorld: '创新·开发',
                    produceMessage: '可自动识别目标类型、判断威胁等级，生成反制方案。'
                }, {
                    FeatureIcon: require('../../src/assets/aboutRY/aboutrongyuan_iconZX.png'),
                    iconWorld: '自信·乐观',
                    produceMessage: '使用操作平板，实时显示电磁信号目标方位，完成目标切换，设备转向等。'
                },],
            activeName: '4',
            carouselfirstheight: 0,
            selectedSidebarIndex: null,
            sidebarOpen: false,
            sidebarWorld: [
                '首页',
                '产品中心',
                '行业应用',
                '关于容远',
                '联系我们',
            ],
            isPCport: true,  //是否是pc端
            viewportWidth: window.innerWidth, // 初始化视口宽度
            bigScreen: true,
            activeIndex: '1',
            images: [
                require('../../src/assets/home_banner.png'),
                require('../../src/assets/banner_bianxie.png'),
                require('../../src/assets/banner_beifu.png'),
                require('../../src/assets/banner_pika.png'),

            ],
            phoneImages: [
                require('../../src/assets/phoneImage/turn4.png'),
                require('../../src/assets/phoneImage/turn2.png'),
                require('../../src/assets/phoneImage/turn3.png'),
                require('../../src/assets/phoneImage/turn.png'),
            ],

            productCenter: [
                {
                    src: require('../../src/assets/productAllPart/portable.png'),
                    title: '便携式无人机探测与反制系统',
                    smallTitle1: '智能化',
                    smallTitle2: '小型化',
                    smallTitle3: '模块化',
                },
                {
                    src: require('../../src/assets/backpack.png'),
                    title: '背负式探测反制系统',
                    smallTitle1: '集成度高',
                    smallTitle2: '方便携带',
                    smallTitle3: '锂电池内置',
                },
                {
                    src: require('../../src/assets/productAllPart/pickUp.png'),
                    title: '低空车载防御平台',
                    smallTitle1: '多手段',
                    smallTitle2: '高集成',
                    smallTitle3: '可机动部署',
                },
                {
                    src: require('../../src/assets/productAllPart/rockchip.png'),
                    title: '瑞芯微主控系列',
                    smallTitle1: '低功耗',
                    smallTitle2: '支持Linux操作系统'
                },
                {
                    src: require('../../src/assets/soar.png'),
                    title: '飞腾主控系列',
                    smallTitle1: '低功耗',
                    smallTitle2: '支持Linux操作系统'
                },
                {
                    src: require('../../src/assets/productAllPart/ruggedizedComputer.png'),
                    title: '加固笔记本',
                    smallTitle1: '坚固性',
                    smallTitle2: '高稳定性',
                    smallTitle3: '可靠性',
                },
            ],
            phoneProductCenter: [
                {
                    src: require('../../src/assets/phoneImage/bianxie.png'),
                    title: '便携式探测与反制系统',
                    smallTitle1: '智能化',
                    smallTitle2: '小型化',
                    smallTitle3: '模块化',
                },
                {
                    src: require('../../src/assets/phoneImage/backpack.png'),
                    title: '背负式探测反制系统',
                    smallTitle1: '集成度高',
                    smallTitle2: '方便携带',
                    smallTitle3: '锂电池内置',
                },
                {
                    src: require('../../src/assets/phoneImage/pick2.png'),
                    title: '低空车载防御平台',
                    smallTitle1: '多手段',
                    smallTitle2: '高集成',
                    smallTitle3: '可机动部署',
                },
                {
                    src: require('../../src/assets/phoneImage/Rockchip.png'),
                    title: '瑞芯微主控系列',
                    smallTitle1: '低功耗',
                    smallTitle2: '支持Linux操作系统'
                },
                {
                    src: require('../../src/assets/phoneImage/soar.png'),
                    title: '飞腾主控系列',
                    smallTitle1: '低功耗',
                    smallTitle2: '支持Linux操作系统'
                },
                {
                    src: require('../../src/assets/phoneImage/ruggedizedComputer.png'),
                    title: '加固笔记本',
                    smallTitle1: '坚固性',
                    smallTitle2: '高稳定性',
                    smallTitle3: '可靠性',
                },
            ],
            aboutRongYuan: [
                {
                    src: require('../../src/assets/phoneImage/home_icon_aboutus1.png'),
                    title: '诚信·尊重'
                },
                {
                    src: require('../../src/assets/phoneImage/home_icon_aboutus2.png'),
                    title: '责任·担当'
                },
                {
                    src: require('../../src/assets/phoneImage/home_icon_aboutus3.png'),
                    title: '创新·开放'
                },
                {
                    src: require('../../src/assets/phoneImage/home_icon_aboutus4.png'),
                    title: '自信·乐观'
                },

            ],
            form: {
                name: '',

                resource: '',

            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                ],
                telephone: [
                    { required: true, message: '请输入电话号码', trigger: 'blur' },
                    { pattern: /^[0-9]{11}$/, message: '电话号码格式不正确，应为11位数字', trigger: 'blur' }
                ],
                unit: [
                    { required: true, message: '请选择单位', trigger: 'change' }
                ],
                resource: [
                    { required: true, message: '请选择时间', trigger: 'change' }
                ],
            },
            currentIndex: null,
            currentIndex22: 0,
        }
    },
    methods: {
        
        setActiveIndex(index,) {
            this.activeImgIndex = index; // 更新当前活动索引
        },
        getMarginStyle(index) {
            // console.log(this.titleSelect, 'this.titleSelect');
            if ([0,1, 2,3].includes(index)) {
                return { marginRight: '1%' };
            }
            return {};
        },
        startCarousel() {
            this.stopCarousel(); // 确保没有重复的定时器
            this.startAnimation()
        },
        stopCarousel() {
            clearInterval(this.animTimer);
        },
        turnleft() {

            clearTimeout(this.debounceTimeout);  // 清除之前的定时器

            this.debounceTimeout = setTimeout(() => {
                const elements = document.querySelectorAll('.background11');
                elements.forEach(element => {
                    element.style.transition = 'transform 0.5s ease';
                });
                // 更新索引
                this.currentIndex22--
                if (this.currentIndex22 === -1) {
                    elements.forEach(element => {
                        this.currentIndex22 = 4    //回到最后一张克隆图
                        element.style.transition = 'none';   //取消过渡动画
                        setTimeout(() => {
                            //回到最后一张真图片
                            this.currentIndex22 = 3
                            element.style.transition = 'transform 0.5s ease';
                        }, 0);
                    });

                }
            }, 300); // 设置防抖延迟时间（300毫秒）

        },
        turnright() {

            clearTimeout(this.debounceTimeoutright);  // 清除之前的定时器

            this.debounceTimeoutright = setTimeout(() => {
                const elements = document.querySelectorAll('.background11');
                // this.currentIndex22 = (this.currentIndex22 + 1) % this.images.length;
                this.currentIndex22++;
                if (this.currentIndex22 >= this.images.length) {
                    this.righttimer = setTimeout(() => {
                        elements.forEach(element => {
                            element.style.transition = 'none'
                        })
                        this.currentIndex22 = 0;

                    }, 500);
                } else {
                    elements.forEach(element => {
                        element.style.transition = 'transform 0.5s ease';
                    });
                }
            }, 300); // 设置防抖延迟时间（300毫秒）
        },
        startAnimation() {
            this.animTimer = setInterval(() => {
                this.currentIndex22++;
                const elements = document.querySelectorAll('.background11');

                // 当currentIndex到达原数组长度时，重置为0
                elements.forEach(element => {
                    element.style.transition = 'transform 0.5s ease';
                });
                if (this.currentIndex22 >= this.images.length) {
                    setTimeout(() => {
                        this.currentIndex22 = 0;
                        // 遍历每个元素并设置 transition 属性为 none
                        elements.forEach(element => {
                            element.style.transition = 'none';
                        });
                    }, 500);
                }
            }, 3000); // 每3秒切换一次
        },
        closeSider() {
            this.sidebarOpen = !this.sidebarOpen
            this.selectedSidebarIndex = null
        },
        changeSidebar(index) {
            this.selectedSidebarIndex = index;
        },
        handleClick(index) {
            switch (+index) {
                case 1:
                    this.$router.push('/')
                    break;
                case 2:
                    this.$router.push('/productCenter')
                    break;
                case 3:
                    // 点击行业应用

                    break;
                case 4:

                    break;
                case 5:
this.$router.push('/contactUs')
                    break;

                default:
                    break;
            }
            console.log(e, 'eee');

        },
        toNextPage() {
            this.$router.push('/productCenter');
        },
        handleMouseEnter(index) {
            this.currentIndex = index
        },
        handleMouseLeave() {
            this.currentIndex = null
        },
        handleViewportChange(mql) {
            this.viewportWidth = window.innerWidth; // 更新视口宽度
            if (mql.matches) {
                console.log("视口宽度小于 768px");
                this.isPCport = false
                this.carouselfirstheight = 176.5
                // 在这里执行你想要的方法
            } else {
                console.log("视口宽度大于等于 768px");
                this.isPCport = true
                this.carouselfirstheight = 450
                // 在这里执行你想要的方法
            }
        },
        getPageWidth() {
            console.log('window.innerWidth', window.innerWidth);

            const width = window.innerWidth;
            if (width > 768) {
                this.carouselheight = width * 0.239
            } else {
                this.carouselheight = width * 0.50427
            }


        },
        resetAnimations() {
            // 先隐藏所有 imgItems
            this.imgItems.forEach((item, index) => {
                this.$set(this.imgItems, index, { ...item, visible: false });
            });

            // 重新显示并触发动画
            this.imgItems.forEach((item, index) => {
                setTimeout(() => {
                    this.$set(this.imgItems, index, { ...item, visible: true });
                }, index * 500); // 每个 item 延迟500毫秒
            });
        },
        showItems() {
            // 显示所有的 imgItems
            this.imgItems.forEach((item, index) => {
                this.$set(this.imgItems, index, { ...item, visible: true });
            });
        },
        handleScroll() {
            const part4 = this.$refs.part4;
            const rect = part4.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // 检查 part4 是否在视口内
            if (rect.top <= windowHeight && rect.bottom >= 0) {
                // 如果 part4 在视口内且动画未执行过
                //保证视口内执行一次动画而不是多次
                if (!this.hasAnimated) {
                    this.resetAnimations();
                    this.hasAnimated = true; // 标记动画已执行
                }
            } else {
                // 当 part4 离开视口时重置动画状态
                this.hasAnimated = false; // 重置标记
            }
        },
        //  handleResize(entries) {
        //    entries.forEach(entry => {
        //     const newHeight = (entry.contentRect.width *(450/1881.6)).toFixed(2)
        //     console.log(entry.contentRect.width,'entry.contentRect.height;');

        //  if (this.carouselheight !== newHeight) {
        //       this.carouselheight = newHeight; // 更新 carouselheight
        //     }
        //   // 更新 carouselheight
        //   });
        // }
    },
    computed: {
        newimg() {
            return [...this.images, this.images[0]]
        },
        newimgphone() {
            return [...this.phoneImages, this.phoneImages[0]]
        },
        carouselStyle() {
            return {
                height: this.carouselheight ? `${this.carouselheight}px` : `${this.carouselfirstheight}px` // 如果有值，则设置高度，否则为 auto
            };
        },


    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.getPageWidth);
        this.getPageWidth()
        this.showItems(); // 初始显示发展历程
        // 创建 MediaQueryList 对象
        this.mediaQueryList = window.matchMedia("(max-width: 768px)");
        this.handleViewportChange(this.mediaQueryList);

        // 添加事件监听器
        this.mediaQueryList.addListener(this.handleViewportChange);
        this.startAnimation();
    },
    beforeDestroy() {
        // 移除事件监听器
        clearInterval(this.getPageWidthTimer);
        clearInterval(this.animTimer);
        this.mediaQueryList.removeListener(this.handleViewportChange);
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@media (min-width: 380px) {
    .eleDistrubTotalPhone {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;

        .header {
            margin: 0 12px;
            box-sizing: border-box;
            max-height: 44px;
            width: 93.6%;
            display: flex;
            justify-content: space-between;
            align-content: center;

            .left {
                width: 45%;
                display: flex;
                justify-content: space-between;
                align-content: center;

                .new {}

                .leftImg1 {
                    max-width: 64.5px;
                    height: 30px;
                    margin-right: 4%;
                }

                .leftImg2 {
                    max-width: 100%;
                    height: auto;
                    margin: auto;
                }
            }

            .right {
                width: 10%;
            }
        }

        .main {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            width: 100%;

            .trytry11 {
                position: relative;
                width: 100%;
                /* padding-top: 23.4375%; */
                height: 176.5px;
                overflow: hidden;
                /* 隐藏超出部分 */

                .background11 {
                    position: absolute;
                    width: 100%;
                    padding-top: 50.427%;
                    background-size: cover;
                    /* 使背景图片覆盖整个盒子 */
                    background-position: center;
                    /* 背景居中 */
                    background-repeat: no-repeat;
                    /* 不重复背景图片 */
                    transition: left 1s ease;

                    /* 添加平滑的过渡效果 */
                    .iconleft,
                    .iconright {
                        position: absolute;
                        width: 2%;
                        height: 6.8%;
                        top: 50%;
                        transform: translateY(-50%);
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .iconleft {
                        position: absolute;
                        width: 2%;
                        height: 6.8%;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 2%;
                        background-image: url('../../src/assets/phoneImage/left.png');
                        background-color: transparent;
                        background-size: cover;
                        /* 使背景图片覆盖整个盒子 */
                        background-position: center;
                        /* 背景居中 */
                        background-repeat: no-repeat;
                        /* 不重复背景图片 */
                    }

                    .iconright {
                        position: absolute;
                        background-image: url('../../src/assets/phoneImage/right.png');
                        background-size: cover;
                        /* 使背景图片覆盖整个盒子 */
                        background-position: center;
                        /* 背景居中 */
                        background-repeat: no-repeat;
                        /* 不重复背景图片 */
                        width: 2%;
                        height: 6.8%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 2%;
                    }

                    .turnbuttom {
                        position: absolute;
                        top: 90%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 16.8%;
                        height: 4.5%;
                        display: flex !important;
                        justify-content: space-evenly;
                        align-items: center;
                        background-color: transparent;

                        .litlecircle {
                            width: 13.5%;
                            height: 100%;
                            background-color: #ebebed;
                            color: transparent;
                            border-radius: 50%;
                        }

                        .isActiveHover {
                            width: 18%;
                            border-radius: 41%;
                        }
                    }
                }
            }

            .carousel {
                z-index: 1;
                position: relative;
                width: 100%;
                height: 176.5px;
                overflow: hidden;

                :deep() .el-carousel__indicators--outside {
                    position: absolute;
                    height: 10%;
                    top: 90%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                :deep() .el-carousel__indicators--outside button {
                    height: 5px;
                    border-radius: 3px;
                }

                :deep() .el-carousel__container {
                    height: 176.5px;
                    overflow: hidden;
                }

                :deep() .el-carousel__arrow--left {
                    top: 50%;
                    left: 3%;
                    color: #ebebed;
                    background-color: rgba(255, 255, 255, .4);
                    width: 30px;
                    height: 30px;
                }

                :deep() .el-carousel__arrow--right {
                    top: 50%;
                    right: 3%;
                    color: #ebebed;
                    background-color: rgba(255, 255, 255, .4);
                    width: 30px;
                    height: 30px;
                }

                .carouselImg {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 176.5px;
                    position: relative;

                    .carouselitem {
                        width: 100%;
                        height: 176.5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .img {
                            max-width: 100%;
                            height: auto;
                            object-fit: cover;
                        }

                        .sidebarpart {
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 20;
                            width: 100px;
                            height: 176px;
                            background: #ffffff;
                            border-radius: 5px;
                            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);

                            .siderinner {
                                width: 100px;
                                height: 20%;
                                display: flex;
                                justify-content: center;
                                align-content: center;
                                font-size: 14px;
                                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                font-weight: 400;
                                text-align: center;
                                color: #000000;

                                .siderItem {
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &.active {
                                        background: rgba(10, 113, 223, 0.20);
                                        /* 选中时的背景颜色 */
                                    }
                                }

                            }
                        }
                    }
                }

                .carouselImg .el-icon-arrow-left,
                .carouselImg .el-icon-arrow-right {
                    font-size: 40px !important;
                    /* 根据需要调整大小 */
                    color: #fff;
                    /* 可选：更改颜色 */
                }
            }

            .bodys {
                padding: 0 12px;
                box-sizing: border-box;
                border: #ffffff .1px solid;
                width: 100%;

                .body2 {
                    .body2Img {
                        margin: 30px 0;
                        width: 159.5px;
                        height: 24.5px;
                    }

                    .body2product {
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-start;
                        flex-wrap: wrap;
                    }

                    .body2_1 {
                        background: #ebebed;
                        max-width: 169.5px;
                        height: 169.5px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        margin-right: 5px;
                        margin-bottom: 13px;
                        border-radius: 6px;
                        position: relative;

                        .new {}

                        .bodyImg {
                            display: flex !important;
                            width: 48.3%;
                            height: 145.5px;
                            align-items: center;
                            justify-content: center;
                        }

                        .bodyHover {
                            width: 48.3%;
                            height: 145.5px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #0467bf;
                            opacity: 0.7;
                            border-radius: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-content: center;

                            /* align-items: center; */
                            .new {}

                            .circle {
                                display: flex !important;
                                /* 使用 Flexbox 布局 */
                                align-items: center;
                                justify-content: center !important;
                                margin-left: 38%;
                                background-color: #ffffff;
                                width: 92px;
                                height: 92px;
                                border-radius: 50%;
                                text-align: center;

                            }

                            .hoverWorld {
                                color: #FFFFFF;

                                .detail-link {
                                    cursor: pointer;
                                    /* 鼠标悬停时显示小手 */
                                }

                                .hoverWorldTitle {
                                    font-size: 22px;
                                    margin: 23px 0 15px 0;
                                }

                                .index34 {
                                    font-size: 18px;
                                    margin-bottom: 22px;

                                    .span1 {
                                        margin-right: 11px;
                                    }

                                }

                                .index0125 {
                                    font-size: 18px;
                                    margin-bottom: 22px;
                                }

                                .detail-link {
                                    font-size: 15px;

                                    .detail-link-img {
                                        width: 14px;
                                        height: 11px;
                                    }
                                }

                                .detail-link:hover {
                                    text-decoration: underline;
                                    /* 可选：悬停时添加下划线 */
                                }
                            }
                        }

                        .productWorld {
                            width: 169.5px;
                            line-height: 24px;
                            background: #0a71df;
                            border-radius: 0px 0px 6px 6px;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }
                }

                .body3 {
                    .body3Img {
                        width: 100%;
                        width: max-content();
                        height: auto;
                        object-fit: cover;
                        margin: 30px 0 0 0;
                    }

                    .body3Part {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 59px;
                        flex-direction: column;

                        .image-container {
                            width: 100% !important;
                            padding-top: 36.66%;
                            /* 保持 16:9 比例（高度 / 宽度 * 100%） */
                            border-radius: 10px;
                            margin-bottom: 2%;
                            background-image: url('../../src/assets/phoneImage/1.png');
                            background-size: cover;
                            /* 使背景图片覆盖整个盒子，保持比例 */
                            background-position: center;
                            /* 背景居中 */
                            background-repeat: no-repeat;
                            /* 不重复背景图片 */
                            position: relative;

                            .eleConfront {
                                width: 100%;
                                line-height: 24px;
                                position: absolute;
                                /* 绝对定位 */
                                bottom: 4.8%;
                                /* 垂直居中 */
                                left: 0;
                                /* 水平居中 */
                                color: white;
                                /* 设置文本颜色 */
                                background-color: rgba(0, 0, 0, 0.4);
                                /* 半透明背景 */
                                border-radius: 5px;
                                /* 圆角 */
                                text-align: left;
                                /* 文本居中 */
                                font-size: 15px;
                                padding-left: 30px;
                                box-sizing: border-box;
                                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                font-weight: 400;
                                letter-spacing: 0.8px;
                            }
                        }


                        .body3Img2 {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            position: relative;

                            /* 使子元素可以绝对定位 */
                            .body3Img2-1 {
                                width: 48.75%;
                                padding-top: 36.25%;
                                border-radius: 10px;
                                background-image: url('../../src/assets/phoneImage/3.png');
                                background-size: cover;
                                /* 使背景图片覆盖整个盒子，保持比例 */
                                background-position: center;
                                /* 背景居中 */
                                background-repeat: no-repeat;
                                /* 不重复背景图片 */
                                position: relative;

                                .eleConfront1 {
                                    width: 100%;
                                    line-height: 24px;
                                    position: absolute;
                                    /* 绝对定位 */
                                    bottom: 4.8%;
                                    /* 垂直居中 */
                                    left: 0%;
                                    /* 水平居中 */
                                    color: white;
                                    /* 设置文本颜色 */
                                    background-color: rgba(0, 0, 0, 0.4);
                                    /* 半透明背景 */
                                    border-radius: 5px;
                                    /* 圆角 */
                                    text-align: left;
                                    /* 文本居中 */
                                    font-size: 15px;
                                    padding-left: 30px;
                                    box-sizing: border-box;
                                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                    font-weight: 400;
                                    letter-spacing: 0.8px;
                                }
                            }

                            .body3Img2-2 {
                                width: 48.75%;
                                padding-top: 36.25%;
                                border-radius: 10px;
                                background-image: url('../../src/assets/phoneImage/3.png');
                                background-size: cover;
                                /* 使背景图片覆盖整个盒子，保持比例 */
                                background-position: center;
                                /* 背景居中 */
                                background-repeat: no-repeat;
                                /* 不重复背景图片 */
                                position: relative;

                                .eleConfront2 {
                                    width: 100%;
                                    line-height: 24px;
                                    position: absolute;
                                    /* 绝对定位 */
                                    bottom: 4.8%;
                                    /* 垂直居中 */
                                    left: 0%;
                                    /* 水平居中 */
                                    color: white;
                                    /* 设置文本颜色 */
                                    background-color: rgba(0, 0, 0, 0.4);
                                    /* 半透明背景 */
                                    border-radius: 5px;
                                    /* 圆角 */
                                    text-align: left;
                                    /* 文本居中 */
                                    font-size: 20px;
                                    padding-left: 30px;
                                    box-sizing: border-box;
                                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                    font-weight: 400;
                                    letter-spacing: 0.8px;
                                }
                            }




                        }
                    }
                }

                .body4 {
                    width: 100%;

                    .body4Img {
                        width: 162.5px;
                        height: 24.5px;
                        margin: 30px 0 30px 0;
                    }

                    .aboutRY {
                        width: 100%;
                        ;
                        height: 421.2px;
                        background: #0467bf;
                        border-radius: 6px;
                        border: #0467bf 1px solid;
                        padding: 18px 9px 0 9px;
                        box-sizing: border-box;
                        position: relative;

                        .introduceworld {

                            font-size: 13px;
                            text-indent: 2em;
                            width: 100%;
                            height: 301px;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: #ffffff;
                            line-height: 24px;
                            letter-spacing: 0.52px;

                            p {
                                margin: 0;
                                padding: 0;
                            }
                        }

                        .iconWorld {
                            display: flex;
                            justify-content: space-between;
                            align-content: space-between;
                            flex-wrap: wrap;
                            position: absolute;
                            width: 228px;
                            height: 50px;
                            left: 50%;
                            bottom: 6%;
                            transform: translateX(-50%);

                            .aboutIconworld {
                                /* margin: 0 70px 0 10px;  */
                                font-size: 14px;
                                color: #ffffff;
                                line-height: 24px;
                                letter-spacing: 0.84px;
                            }
                        }
                    }

                }

                .body5 {
                    .body5Img {
                        width: 110.5px;
                        height: 24.5px;
                        margin: 50px 0 60px 0;

                        .elform {
                            padding-left: 3%;

                            .labelItem {
                                margin-left: 2%;
                            }
                        }

                    }

                    .body5admit {
                        width: 312.5px;
                        height: 45px;
                    }
                }
            }
        }

        .footer {
            width: 100%;
            height: 195.5px;
            padding: 0 5px 0 5px;
            background: #656569;
            /* margin: 0 auto; */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;

            .linkWe {
                position: absolute;
                top: -42%;
                left: 1%;
                height: 13.5px;
                font-size: 14px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
            }

            .sink {
                width: 27px;
                height: 1px;
                background: #ffffff;
                position: absolute;
                top: -7%;
                left: 1%;
            }

            .top {
                width: 300px;
                display: flex;
                position: relative;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 2px;

                .topLeft {
                    padding-right: 19%;
                    width: 117px;
                    height: 30px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-content: space-between;
                    flex-wrap: wrap;
                    line-height: 10px;
                    font-size: 12px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                    font-weight: 400;
                    color: #fff;
                }

                .topRight {
                    width: 120px;
                    height: 48px;

                    .QRcode1 {
                        margin-right: 10px;
                        margin-bottom: 3px;
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            .divider {
                width: 100%;
                height: 0.5px;
                margin-top: 9px;
                background: #acacb1;
            }

            .bottom {
                margin-top: 22px;
                font-size: 10px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 20px;
                letter-spacing: 0.4px;
            }


        }
    }
}

@media (min-width: 768px) {
    .eleDistrubTotal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0 auto;

        .header {
            max-height: 69px;
            width: 62.5%;
            display: flex;
            justify-content: space-between;
            align-content: center;

            .left {
                width: 35%;
                display: flex;
                justify-content: space-between;
                align-content: center;

                .leftImg1 {
                    max-width: 100%;
                    height: auto;
                    margin-right: 4%;
                }

                .leftImg2 {
                    max-width: 100%;
                    height: auto;
                    margin: auto;
                }
            }

            .right {
                width: 46%;
            }
        }

        .main {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            width: 100%;
@keyframes slideIn {
    from {
        transform: translateX(-100%); /* 从左侧开始 */
        opacity: 0; /* 初始透明度为0 */
    }
    to {
        transform: translateX(0); /* 移动到目标位置 */
        opacity: 1; /* 最终透明度为1 */
    }
}
            .banner {
                width: 100%;
                height: 550px;
                background-image: url('../../src/assets/aboutRY/aboutrongyuan_banner.png');
                background-size: contain;
                /* 使背景图片覆盖整个盒子 */
                background-position: center;
                /* 背景居中 */
                background-repeat: no-repeat;
                /* 不重复背景图片 */
                position: relative;
            }
            .bannertuceng{
                width: 1421px;
                height: 451px;
                position: absolute;
                top: 5px;
                left: 0;
                animation: slideIn 1s ease-out;
                    /* 1秒的动画，使用ease-out效果 */
            }
                        @keyframes slideIn {
                            from {
                                transform: translateX(-100%);
                                /* 从左侧开始 */
                                opacity: 0;
                                /* 初始透明度为0 */
                            }
            
                            to {
                                transform: translateX(0);
                                /* 移动到目标位置 */
                                opacity: 1;
                                /* 最终透明度为1 */
                            }
                        }
            .carousel {
                position: relative;
                width: 100%;
                /* height: 450px; */
                /* padding-top: 23.4375%; */
                overflow: hidden;

                :deep() .el-carousel__indicators--outside {
                    position: absolute;
                    width: 20%;
                    height: 10%;
                    top: 91%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                :deep() .el-carousel__indicators--outside button {
                    height: 5px;
                    border-radius: 3px;
                }

                :deep() .el-carousel__container {
                    height: 100%;
                }

                :deep() .el-carousel__arrow--left {
                    top: 50%;
                    left: 15%;
                    /* transform: translateX(-50%); */
                    color: #ebebed;
                    background-color: rgba(255, 255, 255, .4);
                    width: 60px;
                    height: 60px;
                }

                :deep() .el-carousel__arrow--right {
                    position: absolute;
                    top: 50%;
                    right: 15%;
                    /* transform: translateX(-50%); */
                    width: 60px;
                    height: 60px;

                    color: #ebebed;
                    background-color: rgba(255, 255, 255, .4);

                }

                :deep() .el-icon-arrow-left,
                :deep() .el-icon-arrow-right {
                    font-size: 40px !important;
                    /* 根据需要调整大小 */
                    color: #fff;
                    /* 可选：更改颜色 */
                }

                .carouselImg {
                    width: 100%;
                    /* height: 450px; */
                    /* padding-top: 23.4375%; */
                    position: relative;

                    .carouselitem {
                        width: 100%;
                        /* height: 450px; */
                        padding-top: 23.4375%;
                        object-fit: cover;

                        .img {
                            object-fit: cover;
                        }
                    }
                }

            }

            .trytry11 {
                position: relative;
                width: 100%;
                /* padding-top: 23.4375%; */
                height: 450px;
                overflow: hidden;
                /* 隐藏超出部分 */

                .background11 {
                    position: absolute;
                    width: 100%;
                    padding-top: 23.4375%;
                    background-size: cover;
                    /* 使背景图片覆盖整个盒子 */
                    background-position: center;
                    /* 背景居中 */
                    background-repeat: no-repeat;
                    /* 不重复背景图片 */
                    transition: left 1s ease;

                    /* 添加平滑的过渡效果 */
                    .iconleft,
                    .iconright {
                        position: absolute;
                        width: 3.333%;
                        height: 14.2%;
                        top: 50%;
                        transform: translateY(-50%);
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .iconleft {
                        position: absolute;
                        width: 3.333%;
                        height: 14.2%;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 15%;
                        background-image: url('../../src/assets/iconleft.png');
                        background-size: cover;
                        /* 使背景图片覆盖整个盒子 */
                        background-position: center;
                        /* 背景居中 */
                        background-repeat: no-repeat;
                        /* 不重复背景图片 */
                    }

                    .iconright {
                        position: absolute;
                        background-image: url('../../src/assets/iconright.png');
                        background-size: cover;
                        /* 使背景图片覆盖整个盒子 */
                        background-position: center;
                        /* 背景居中 */
                        background-repeat: no-repeat;
                        /* 不重复背景图片 */
                        width: 3.333%;
                        height: 14.2%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 14%;
                    }

                    .turnbuttom {
                        position: absolute;
                        top: 90%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 7.34%;
                        height: 4%;
                        display: flex !important;
                        justify-content: space-evenly;
                        align-items: center;
                        background-color: transparent;

                        .litlecircle {
                            width: 13%;
                            height: 100%;
                            background-color: #ebebed;
                            color: transparent;
                            border-radius: 50%;
                        }

                        .isActiveHover {
                            width: 20%;
                            border-radius: 41%;
                        }
                    }
                }
            }



            .bodys {
                padding: 0;
                margin: 0 auto;
                width: 62.5%;

                .part1 {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;

                    .part1Title {
                        width: 100%;
                        font-size: 60px;
                        font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0-85 Bold";
                        font-weight:800;
                        color: #333333;
                        line-height: 60px;
                        margin: 78px 0 42px 0;
                    }

                    .part1Message {
                        width: 100%;
                        font-size: 28px;
                        font-family: "Alibaba PuHuiTi 2.0","Alibaba PuHuiTi 2.0-85 Bold";
                        font-weight: 800;
                        color: #333333;
                        line-height: 55px;
                        margin-bottom: 80px;
                        .hover-text {
                                color: 333333;
                                /* 默认颜色 */
                                transition: color 0.3s;
                                /* 添加过渡效果 */
                            }
                        
                            .hover-text:hover {
                                color: #0467BF;
                                /* 鼠标移入时的颜色 */
                            }
                    }

                    .part1Img {
                        width: 100%;
                        height: 248px;
                        overflow: hidden;
                        margin-bottom: 100px;
                        background-image: url('../../src/assets/aboutRYCenter.png');
                        background-size: contain;
                        /* 使背景图片覆盖整个盒子 */
                        background-position: center;
                        /* 背景居中 */
                        background-repeat: no-repeat;
                        /* 不重复背景图片 */
                    }
                }
                                .part2 {
                                    width: 100%;
                                    height: auto;
                                    /* height: 572.41px; */
                                    background: #0467bf;
                                    border-radius: 12px;
                                    border: #0467bf 1px solid;
                                    padding-left: 4%;
                                    padding-right: 4%;
                                    padding-top: 2%;
                                    margin-bottom: 2%;
                                    box-sizing: border-box;
                
                                    .introduceworld {
                                        width: 100%;
                                        height: auto;
                                        font-size: 24px;
                                        font-family: 'Microsoft YaHei, Microsoft YaHei-Regular';
                                        font-weight: 400;
                                        text-align: left;
                                        color: #ffffff;
                                        line-height: 2em;
                                        letter-spacing: 2px;
                
                                        p {
                                            margin: 0;
                                            text-indent: 2em;
                                            margin-bottom: 3%;
                                        }
                                    }
                
                                    .iconWorld {
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-around;
                                        align-items: center;
                                        flex-wrap: nowrap;
                                        margin-top: 5%;
                                        margin-bottom: 3%;
                
                                        .everyPart {
                                            width: 20%;
                
                                            .aboutIconworld {
                                                width: 25%;
                                                margin-right: 1%;
                                                font-size: 24px;
                                                color: #ffffff;
                                                line-height: 17px;
                                            }
                                        }
                
                                    }
                                }
               .part3{
                display: flex;
                    justify-content: flex-start;
                    align-content: flex-start;
                    flex-wrap: wrap;
                .productFeatureItem {
                        width: 24%;
                        background-color: #FFFFFF;
                        box-sizing: border-box;
                        border: #F7F7F7 2px solid;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 1%;
                
                        .iconBox {
                            width: 150px;
                            height: 164px;
                            /* border-radius: 50%; */
                            /* background: #0467bf; */
                            overflow: hidden;
                            margin-top: 31px;
                
                            .itemIcon {
                                max-width: 100%;
                                /* 图片最大宽度为盒子宽度 */
                                height: auto;
                            }
                        }
                
                        .itemWorld {
                            font-size: 32px;
                            font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                            font-weight: normal;
                            color: #0467bf;
                            line-height: 77px;
                            letter-spacing: 0.64px;
                        }
                
                        .itemMessage {
                            font-size: 20px;
                            font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                            font-weight: normal;
                            color: #000000;
                            line-height: 38px;
                            margin: 0 5% 45px 4%;
                            
                        }
                    }
                                   .active {
                                       box-shadow: 1px 7px 18px 0px rgba(117, 117, 117, 0.59);
                                       background-color: #F7F7F7;
                                       /* 添加阴影效果 */
                                   }
               }
             
                .body2 {
                    .body2Img {
                        margin: 8% 0;
                        width: 26.58%;
                        height: auto;
                    }

                    .body2product {
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-start;
                        flex-wrap: wrap;
                    }

                    .body2_1 {
                        background: #ebebed;
                        width: 380px;
                        height: 300px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 30px;
                        border-radius: 12px;
                        position: relative;

                        .new {}

                        .bodyImg {
                            display: flex !important;
                            width: 380px;
                            height: 250px;
                            align-items: center;
                            justify-content: center;
                        }

                        .bodyHover {
                            width: 380px;
                            height: 300px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #0467bf;
                            opacity: 0.7;
                            border-radius: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-content: center;

                            /* align-items: center; */
                            .new {}

                            .circle {
                                display: flex !important;
                                /* 使用 Flexbox 布局 */
                                align-items: center;
                                justify-content: center !important;
                                margin-left: 38%;
                                background-color: #ffffff;
                                width: 92px;
                                height: 92px;
                                border-radius: 50%;
                                text-align: center;

                            }

                            .hoverWorld {
                                color: #FFFFFF;

                                .detail-link {
                                    cursor: pointer;
                                    /* 鼠标悬停时显示小手 */
                                }

                                .hoverWorldTitle {
                                    font-size: 22px;
                                    margin: 23px 0 15px 0;
                                }

                                .index34 {
                                    font-size: 18px;
                                    margin-bottom: 22px;

                                    .span1 {
                                        margin-right: 11px;
                                    }

                                }

                                .index0125 {
                                    font-size: 18px;
                                    margin-bottom: 22px;
                                }

                                .detail-link {
                                    font-size: 15px;

                                    .detail-link-img {
                                        width: 14px;
                                        height: 11px;
                                    }
                                }

                                .detail-link:hover {
                                    text-decoration: underline;
                                    /* 可选：悬停时添加下划线 */
                                }
                            }
                        }

                        .productWorld {
                            width: 380px;
                            font-size: 20px;
                            line-height: 50px;
                            color: #FFFFFF;
                            background: rgba(4, 103, 191, 0.80);
                            border-bottom-left-radius: 12px;
                            border-bottom-right-radius: 12px;
                        }
                    }
                }

                .body3 {
                    width: 100%;

                    .body3Img {
                        width: max-content();
                        height: auto;
                        object-fit: cover;
                        /* 保持图片的比例并覆盖盒子 */
                        margin: 60px 0 0 0;
                    }

                    .body3Part {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 59px;
                        flex-direction: column;

                        .image-container {
                            width: 100%;
                            padding-top: 36.66%;
                            /* 保持 16:9 比例（高度 / 宽度 * 100%） */
                            border-radius: 10px;
                            margin-bottom: 2%;
                            background-image: url('../../src/assets/eleConfront.png');
                            background-size: cover;
                            /* 使背景图片覆盖整个盒子，保持比例 */
                            background-position: center;
                            /* 背景居中 */
                            background-repeat: no-repeat;
                            /* 不重复背景图片 */
                            position: relative;

                            .eleConfront {
                                width: 100%;
                                line-height: 54px;
                                position: absolute;
                                /* 绝对定位 */
                                bottom: 4.8%;
                                /* 垂直居中 */
                                left: 0;
                                /* 水平居中 */
                                color: white;
                                /* 设置文本颜色 */
                                background-color: rgba(0, 0, 0, 0.4);
                                /* 半透明背景 */
                                border-radius: 5px;
                                /* 圆角 */
                                text-align: left;
                                /* 文本居中 */
                                font-size: 20px;
                                padding-left: 30px;
                                box-sizing: border-box;
                                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                font-weight: 400;
                                letter-spacing: 0.8px;
                            }
                        }


                        .body3Img2 {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            position: relative;

                            /* 使子元素可以绝对定位 */
                            .body3Img2-1 {
                                width: 48.75%;
                                padding-top: 36.25%;
                                border-radius: 10px;
                                background-image: url('../../src/assets/netWorking.png');
                                background-size: cover;
                                /* 使背景图片覆盖整个盒子，保持比例 */
                                background-position: center;
                                /* 背景居中 */
                                background-repeat: no-repeat;
                                /* 不重复背景图片 */
                                position: relative;

                                .eleConfront1 {
                                    width: 100%;
                                    line-height: 54px;
                                    position: absolute;
                                    /* 绝对定位 */
                                    bottom: 4.8%;
                                    /* 垂直居中 */
                                    left: 0%;
                                    /* 水平居中 */
                                    color: white;
                                    /* 设置文本颜色 */
                                    background-color: rgba(0, 0, 0, 0.4);
                                    /* 半透明背景 */
                                    border-radius: 5px;
                                    /* 圆角 */
                                    text-align: left;
                                    /* 文本居中 */
                                    font-size: 20px;
                                    padding-left: 30px;
                                    box-sizing: border-box;
                                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                    font-weight: 400;
                                    letter-spacing: 0.8px;
                                }
                            }

                            .body3Img2-2 {
                                width: 48.75%;
                                padding-top: 36.25%;
                                border-radius: 10px;
                                background-image: url('../../src/assets/lowDefense.png');
                                background-size: cover;
                                /* 使背景图片覆盖整个盒子，保持比例 */
                                background-position: center;
                                /* 背景居中 */
                                background-repeat: no-repeat;
                                /* 不重复背景图片 */
                                position: relative;

                                .eleConfront2 {
                                    width: 100%;
                                    line-height: 54px;
                                    position: absolute;
                                    /* 绝对定位 */
                                    bottom: 4.8%;
                                    /* 垂直居中 */
                                    left: 0%;
                                    /* 水平居中 */
                                    color: white;
                                    /* 设置文本颜色 */
                                    background-color: rgba(0, 0, 0, 0.4);
                                    /* 半透明背景 */
                                    border-radius: 5px;
                                    /* 圆角 */
                                    text-align: left;
                                    /* 文本居中 */
                                    font-size: 20px;
                                    padding-left: 30px;
                                    box-sizing: border-box;
                                    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                                    font-weight: 400;
                                    letter-spacing: 0.8px;
                                }
                            }




                        }
                    }
                }

                .body4 {
                    width: 100%;
                    height: 47.7%;

                    .body4Img {
                        width: 27%;
                        height: auto;
                        margin: 90px 0 59px 0;
                    }

                    .aboutRY {
                        width: 100%;
                        height: auto;
                        /* height: 572.41px; */
                        background: #0467bf;
                        border-radius: 12px;
                        border: #0467bf 1px solid;
                        padding-left: 4%;
                        padding-right: 4%;
                        padding-top: 2%;
                        box-sizing: border-box;

                        .introduceworld {
                            width: 100%;
                            height: auto;
                            font-size: 24px;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: #ffffff;
                            line-height: 2em;
                            letter-spacing: 2px;

                            p {
                                margin: 0;
                                text-indent: 2em;
                            }
                        }

                        .iconWorld {
                            width: 100%;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            flex-wrap: nowrap;
                            margin-top: 5%;
                            margin-bottom: 3%;

                            .everyPart {
                                width: 20%;

                                .aboutIconworld {
                                    width: 25%;
                                    margin-right: 1%;
                                    font-size: 24px;
                                    color: #ffffff;
                                    line-height: 17px;
                                }
                            }

                        }
                    }

                }

                .body5 {
                    font-size: 24px;

                    .body5Img {
                        width: 221px;
                        height: 49px;
                        margin: 90px 0 60px 0;
                    }

                    .body5admit {
                        width: 634px;
                        height: 99px;
                    }
                }
            }
        }
  .part4 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
ul {
        list-style: none;
        font-size: 20px;
        padding: 0;
        font-family: 'Alibaba PuHuiTi 2.0', 'Alibaba PuHuiTi 2.0-65 Medium';
        font-weight: normal;
        color: #000000;
        line-height: 31px;
        letter-spacing: -0.4px;
        text-align: start;
    }

    li::before {
        content: "•";
        /* 自定义小点 */
        color: #0467BF;
        /* 设置小点颜色 */
        font-size: 25px;
        word-wrap: break-word;
        /* 允许长单词换行 */
        overflow-wrap: break-word;
        /* 允许长单词换行（现代浏览器支持） */
        max-width: 25%;
        /* 设置最大宽度 */
        /* margin-right: 10px; */
        /* 小点与文本之间的间距 */
    }
      .developMentWorld {
        width: 62.5%;
          font-size: 60px;
          font-family: 'Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 2.0-85 Bold';
          font-weight: 800;
          color: #333333;
          line-height: 80px;
          letter-spacing: -1.2px;
          margin: 80px 0 100px 0;
      }

      .HistoryImg {
        width:100%;
        height: 475px;
        display: flex;
            justify-content: center;
            align-items: flex-start;
          background-image: url('../assets/aboutRY/aboutus_hill.png');
          background-size:contain;
          /* 使背景图片覆盖整个盒子 */
          background-position: center;
          /* 背景居中 */
          background-repeat: no-repeat;
           
          /* 不重复背景图片 */
        .centerImg {
                width: 62.5%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .imgItem {
                        opacity: 0;
                        /* 初始透明度为0 */
                        transform: translateY(20px);
                        /* 初始位置向下偏移 */
                        transition: opacity 0.5s, transform 0.5s;
                        /* 添加过渡效果 */
                        margin: 10px 0;
                        /* 列表项之间的间距 */
                
                    }
                
                    .imgItem.show {
                        opacity: 1;
                        /* 最终透明度为1 */
                        transform: translateY(0);
                        /* 回到原位 */
                    }
            }
                        
        
           
         
      }
  }
        footer {
            width: 100%;
            height: 472px;
            background-color: #656569;
            display: flex;
            justify-content: center;
            align-items: center;
            /* padding:112px 390px 106px 368px; */
            box-sizing: border-box;
          
            .footerPart {
                width: 62.5%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .footleft {
                    width: 65%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    color: #FFFFFF;
                    font-size: 14px;

                    .leftertop {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        text-align: left;
                        line-height: 40px;
                        letter-spacing: 1px;
                        color: #FFFFFF;
                        font-size: 14px;

                        ul {
                            list-style: none;
                            /* 去掉默认的列表样式 */
                            padding: 0;
                            /* 去掉内边距 */
                            margin: 0;
                            /* 去掉外边距 */
                        }

                        li {
                            list-style-type: none;
                            /* 默认隐藏所有 <li> */
                        }

                        li:first-child {
                            list-style-type: disc;
                            /* 显示每个 <ul> 的第一个 <li> 并保留项目符号 */
                            margin-bottom: 20px;
                        }

                        /* 设置第一个 li 的标记颜色为红色 */
                        li:first-child::marker {
                            color: #F5A300;
                            /* 将标记颜色设置为红色 */
                        }
                    }

                    .footleftbottom {
                        margin-top: 10%;
                    }
                }

                .footright {
                    width: 30%;
                    /* height: 200px; */
                    display: flex;
                    justify-content: space-between;
                    align-content: space-between;
                    flex-direction: column;

                    .footNumber {
                        /* margin-top: 20px; */
                        font-size: 12px;
                        color: #FFFFFF;

                        .citymargin {
                            margin: 10px 20px;
                        }

                        .footNumberspan1 {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            flex-direction: row;
                        }

                        .footNumberspan2 {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            flex-direction: row;
                        }
                    }

                    .footCode {
                        width: 100%;
                        height: 98px;
                        margin-top: 10%;
                        background-position: right;
                        /* 背景居中 */

                        background-repeat: no-repeat;
                        background-image: url('../../src/assets/qrCode.png');
                    }
                }
            }


        }
    }
}

.eleDistrubTotalPhone {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;

    .header {
        margin: 0 12px;
        max-height: 44px;
        width: 93.6%;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .left {
            width: 57%;
            display: flex;
            justify-content: space-between;
            align-content: center;

            .new {}

            .leftImg1 {
                max-width: 64.5px;
                height: 30px;
                margin-right: 4%;
            }

            .leftImg2 {
                max-width: 100%;
                height: auto;
                margin: auto;
            }
        }

        .right {
            width: 10%;
        }
    }

    .main {
        margin: 0 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;

        .trytry11 {
            position: relative;
            width: 100%;
            /* padding-top: 23.4375%; */
            height: 176.5px;
            overflow: hidden;
            /* 隐藏超出部分 */

            .background11 {
                position: absolute;
                width: 100%;
                padding-top: 50.427%;
                background-size: cover;
                /* 使背景图片覆盖整个盒子 */
                background-position: center;
                /* 背景居中 */
                background-repeat: no-repeat;
                /* 不重复背景图片 */
                transition: left 1s ease;

                /* 添加平滑的过渡效果 */
                .iconleft,
                .iconright {
                    position: absolute;
                    width: 2%;
                    height: 6.8%;
                    top: 50%;
                    transform: translateY(-50%);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .iconleft {
                    position: absolute;
                    width: 2%;
                    height: 6.8%;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 2%;
                    background-image: url('../../src/assets/phoneImage/left.png');
                    background-color: transparent;
                    background-size: cover;
                    /* 使背景图片覆盖整个盒子 */
                    background-position: center;
                    /* 背景居中 */
                    background-repeat: no-repeat;
                    /* 不重复背景图片 */
                }

                .iconright {
                    position: absolute;
                    background-image: url('../../src/assets/phoneImage/right.png');
                    background-size: cover;
                    /* 使背景图片覆盖整个盒子 */
                    background-position: center;
                    /* 背景居中 */
                    background-repeat: no-repeat;
                    /* 不重复背景图片 */
                    width: 2%;
                    height: 6.8%;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 2%;
                }

                .turnbuttom {
                    position: absolute;
                    top: 90%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 16.8%;
                    height: 4.5%;
                    display: flex !important;
                    justify-content: space-evenly;
                    align-items: center;
                    background-color: transparent;

                    .litlecircle {
                        width: 13.5%;
                        height: 100%;
                        background-color: #ebebed;
                        color: transparent;
                        border-radius: 50%;
                    }

                    .isActiveHover {
                        width: 18%;
                        border-radius: 41%;
                    }
                }
            }
        }

        .carousel {
            z-index: 1;
            position: relative;
            width: 100%;
            height: 176.5px;
            overflow: hidden;

            :deep() .el-carousel__indicators--outside {
                position: absolute;
                height: 10%;
                top: 90%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            :deep() .el-carousel__indicators--outside button {
                height: 5px;
                border-radius: 3px;
            }

            :deep() .el-carousel__container {
                height: 176.5px;
                overflow: hidden;
            }

            :deep() .el-carousel__arrow--left {
                top: 50%;
                left: 3%;
                color: #ebebed;
                background-color: rgba(255, 255, 255, .4);
                width: 30px;
                height: 30px;
            }

            :deep() .el-carousel__arrow--right {
                top: 50%;
                right: 3%;
                color: #ebebed;
                background-color: rgba(255, 255, 255, .4);
                width: 30px;
                height: 30px;
            }

            .carouselImg {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 176.5px;
                position: relative;

                .carouselitem {
                    width: 100%;
                    height: 176.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .img {
                        max-width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    .sidebarpart {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 20;
                        width: 100px;
                        height: 176px;
                        background: #ffffff;
                        border-radius: 5px;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);

                        .siderinner {
                            width: 100px;
                            height: 20%;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            font-size: 14px;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            font-weight: 400;
                            text-align: center;
                            color: #000000;

                            .siderItem {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &.active {
                                    background: rgba(10, 113, 223, 0.20);
                                    /* 选中时的背景颜色 */
                                }
                            }

                        }
                    }
                }
            }

            .carouselImg .el-icon-arrow-left,
            .carouselImg .el-icon-arrow-right {
                font-size: 40px !important;
                color: #fff;
            }
        }

        .bodys {

            border: #ffffff .1px solid;
            width: 100%;

            .body2 {
                .body2Img {
                    margin: 30px 0;
                    width: 159.5px;
                    height: 24.5px;
                }

                .body2product {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: flex-start;
                    flex-wrap: wrap;
                }

                .body2_1 {
                    background: #ebebed;
                    max-width: 169.5px;
                    height: 169.5px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    margin-bottom: 13px;
                    border-radius: 6px;
                    position: relative;

                    .new {}

                    .bodyImg {
                        display: flex !important;
                        width: 48.3%;
                        height: 145.5px;
                        align-items: center;
                        justify-content: center;
                    }

                    .bodyHover {
                        width: 48.3%;
                        height: 145.5px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #0467bf;
                        opacity: 0.7;
                        border-radius: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;

                        /* align-items: center; */
                        .new {}

                        .circle {
                            display: flex !important;
                            /* 使用 Flexbox 布局 */
                            align-items: center;
                            justify-content: center !important;
                            margin-left: 38%;
                            background-color: #ffffff;
                            width: 92px;
                            height: 92px;
                            border-radius: 50%;
                            text-align: center;

                        }

                        .hoverWorld {
                            color: #FFFFFF;

                            .detail-link {
                                cursor: pointer;
                                /* 鼠标悬停时显示小手 */
                            }

                            .hoverWorldTitle {
                                font-size: 22px;
                                margin: 23px 0 15px 0;
                            }

                            .index34 {
                                font-size: 18px;
                                margin-bottom: 22px;

                                .span1 {
                                    margin-right: 11px;
                                }

                            }

                            .index0125 {
                                font-size: 18px;
                                margin-bottom: 22px;
                            }

                            .detail-link {
                                font-size: 15px;

                                .detail-link-img {
                                    width: 14px;
                                    height: 11px;
                                }
                            }

                            .detail-link:hover {
                                text-decoration: underline;
                                /* 可选：悬停时添加下划线 */
                            }
                        }
                    }

                    .productWorld {
                        width: 169.5px;
                        line-height: 24px;
                        background: #0a71df;
                        border-radius: 0px 0px 6px 6px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }

            .body3 {
                .body3Img {
                    width: 202.5px;
                    height: 24.5px;
                    margin: 30px 0 0 0;
                }

                .body3Part {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 59px;
                    flex-direction: column;

                    .image-container {
                        width: 351px;
                        position: relative;
                        /* 使子元素可以绝对定位 */
                        display: inline-block;
                        /* 使容器适应图片大小 */
                    }

                    .eleConfront {
                        width: 100%;
                        line-height: 54px;
                        position: absolute;
                        /* 绝对定位 */
                        bottom: 4.8%;
                        /* 垂直居中 */
                        left: 0;
                        /* 水平居中 */
                        color: white;
                        /* 设置文本颜色 */
                        background-color: rgba(0, 0, 0, 0.4);
                        /* 半透明背景 */
                        border-radius: 5px;
                        /* 圆角 */
                        text-align: left;
                        /* 文本居中 */
                        font-size: 20px;
                        padding-left: 30px;
                        box-sizing: border-box;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                        font-weight: 400;
                        letter-spacing: 0.8px;
                    }

                    .body3Img1 {

                        width: 351px;
                        height: 135px;


                    }

                    .body3Img2 {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        position: relative;

                        /* 使子元素可以绝对定位 */
                        .eleConfront {
                            width: 169.5px;
                            line-height: 24px;
                            position: absolute;
                            /* 绝对定位 */
                            bottom: 4.8%;
                            /* 垂直居中 */
                            left: 0%;
                            /* 水平居中 */
                            color: white;
                            /* 设置文本颜色 */
                            background-color: rgba(0, 0, 0, 0.4);
                            /* 半透明背景 */
                            border-radius: 5px;
                            /* 圆角 */
                            text-align: left;
                            /* 文本居中 */
                            font-size: 15px;
                            padding-left: 30px;
                            box-sizing: border-box;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            font-weight: 400;
                            letter-spacing: 0.8px;
                        }

                        .eleConfront2 {
                            width: 169.5px;
                            line-height: 24px;
                            position: absolute;
                            /* 绝对定位 */
                            bottom: 4.8%;
                            /* 垂直居中 */
                            left: 51.2%;
                            /* 水平居中 */
                            color: white;
                            /* 设置文本颜色 */
                            background-color: rgba(0, 0, 0, 0.4);
                            /* 半透明背景 */
                            border-radius: 5px;
                            /* 圆角 */
                            text-align: left;
                            /* 文本居中 */
                            font-size: 15px;
                            padding-left: 30px;
                            box-sizing: border-box;
                            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                            font-weight: 400;
                            letter-spacing: 0.8px;
                        }

                        .body3Img3 {
                            width: 169.5px;
                            height: 135px;

                        }

                        .body3Img4 {
                            width: 169.5px;
                            height: 135px;
                        }
                    }
                }
            }

            .body4 {
                width: 100%;

                .body4Img {
                    width: 162.5px;
                    height: 24.5px;
                    margin: 30px 0 30px 0;
                }

                .aboutRY {
                    width: 100%;
                    height: 421.2px;
                    background: #0467bf;
                    border-radius: 6px;
                    border: #0467bf 1px solid;
                    padding: 18px 9px 0 9px;
                    box-sizing: border-box;
                    position: relative;

                    .introduceworld {

                        font-size: 13px;
                        text-indent: 2em;
                        width: 100%;
                        height: 301px;
                        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff;
                        line-height: 24px;
                        letter-spacing: 0.52px;

                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    .iconWorld {
                        display: flex;
                        justify-content: space-between;
                        align-content: space-between;
                        flex-wrap: wrap;
                        position: absolute;
                        width: 228px;
                        height: 50px;
                        left: 50%;
                        bottom: 6%;
                        transform: translateX(-50%);

                        .aboutIconworld {
                            /* margin: 0 70px 0 10px;  */
                            font-size: 14px;
                            color: #ffffff;
                            line-height: 24px;
                            letter-spacing: 0.84px;
                        }
                    }
                }

            }

            .body5 {
                .body5Img {
                    width: 110.5px;
                    height: 24.5px;
                    margin: 50px 0 60px 0;

                    .elform {
                        padding-left: 3%;

                        .labelItem {
                            margin-left: 2%;
                        }
                    }

                }

                .body5admit {
                    width: 312.5px;
                    height: 45px;
                }
            }
        }
    }

    .footer {
        width: 100%;
        height: 195.5px;
        padding: 0 5px 0 5px;
        background: #656569;
        /* margin: 0 auto; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;

        .linkWe {
            position: absolute;
            top: -42%;
            left: 1%;
            height: 13.5px;
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
        }

        .sink {
            width: 27px;
            height: 1px;
            background: #ffffff;
            position: absolute;
            top: -7%;
            left: 1%;
        }

        .top {
            width: 300px;
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 2px;

            .topLeft {
                padding-right: 19%;
                width: 117px;
                height: 30px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-content: space-between;
                flex-wrap: wrap;
                line-height: 10px;
                font-size: 12px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                color: #fff;
            }

            .topRight {
                width: 120px;
                height: 48px;

                .QRcode1 {
                    margin-right: 10px;
                    margin-bottom: 3px;
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .divider {
            width: 100%;
            height: 0.5px;
            margin-top: 9px;
            background: #acacb1;
        }

        .bottom {
            margin-top: 22px;
            font-size: 10px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            letter-spacing: 0.4px;
        }


    }
}
</style>
